import _ from 'lodash';
import dayjs from 'dayjs';
import { formatted_payment_details } from '~/utils/tool'
import { recipet_sample } from '~/utils/models'
import { httpClient } from '~/utils/axios'

let base_json = {
  request_type: null,
  request_uid: null,
  timestamp: null,
  items: [],
}

const OSPrintDone = async (order_set) => {
  console.log("OSPrintDone", order_set)
  let url = `/shp/floor/order_sets/${order_set.uid}/print.json`
  await httpClient
  .put(url)
  .then((res) => {
    if (res.data.status === 'success') {
      window.storeCtl.commit('floor/replaeOrderdOrderSet', res.data.data.order_set)
    }
  })
}

//// プリントオブジェクトメソッド
// いい感じに2列にして食えっる
const setDrawer = (input_json) => {
  input_json.items.push({
    type: "pulse",
    pulse: 100,
    drawer: "drawer_1",
  })
  return input_json
}
const setPCut = (input_json) => {
  input_json.items.push({
    type: "cut",
    cut_type: "feed"
  })
  return input_json
}
const setPFeed = (input_json, unit) => {
  input_json.items.push({
    type: "feed",
    unit: unit
  })
  return input_json
}
const setPTextSize = (input_json, width, height) => {
  input_json.items.push({
    type: "text_size",
    width: width,
    height: height,
  })
  return input_json
}
const setPText = (input_json, text) => {
  input_json.items.push({
    type: "text",
    text: text
  })
  return input_json
}
const setPTextAlign = (input_json, align) => {
  input_json.items.push({
    type: "text_align",
    align: align,
  })
  return input_json
}
const setPTextReverse = (input_json, reverse, ul = "false", em = "false", color = "color_1") => {
  input_json.items.push({
    type: "text_reverse",
    reverse: reverse,
    ul: ul,
    em: em,
    color: color,
  })
  return input_json
}
const setPImage = (input_json, w, h, url, c = "color_1", mode = "mono") => {
  input_json.items.push({
    type: "image",
    width: w,
    height: h,
    color: c,
    mode: mode,
    url: url,
  })
  return input_json
}
const setPQr = (input_json, w, h, url, size = 0, symbol_type = "symbol_type", level = "default") => {
  input_json.items.push({
    type: "symbol",
    symbol_type: symbol_type,
    level: level,
    width: w,
    height: h,
    size: size,
    url: url,
  })
  return input_json
}
const setPHr = (input_json, printerWidth = 48) => {
  input_json = setPTextSize(input_json, 1, 1)
  let str = str_for("-", printerWidth)
  input_json = setPText(input_json, str)
  return input_json
}
const setP1Comuns = (input_json, str, font_width = 1, font_height = 1, printerWidth = 48) => {
  input_json = setPTextSize(input_json, font_width, font_height)
  str = `${str}\n`
  input_json = setPText(input_json, str)
  return input_json
}
const setP2Comuns = (input_json, str1, str2, font_width = 1, font_height = 1, printerWidth = 48) => {
  input_json = setPTextSize(input_json, font_width, font_height)

  let str = ""
  str1 = String(str1)
  str2 = String(str2)
  let str1_count = count(str1) * font_width
  let str2_count = count(str2) * font_width
  let str1_amari = str1_count % printerWidth

  // console.log("setP2Comuns", str1_count, str2_count, str1_amari)

  if(str1_count > printerWidth){
    // console.log("3.タイトルが横より長い")
    // 3.タイトルが横より長い
    if((str1_amari + str2_count) > printerWidth){
      let space_size = parseInt((printerWidth - str2_count) / font_width)
      str = str1 + "\n" + str_for(" ", space_size) + str2 + "\n"
    }else{
      let space_size = parseInt((printerWidth - str1_amari - str2_count) / font_width)
      str = str1 + str_for(" ", space_size) + str2 + "\n"
      // console.log("str", space_size, str)  
    }
  }else{
    if((str1_count + str2_count) > printerWidth){
      // console.log("2.タイトル・数が横より長い")
      // 2.タイトル・数が横より長い
      let space_size = parseInt((printerWidth - str2_count) / font_width)
      str = str1 + "\n" + str_for(" ", space_size) + str2 + "\n"
    }else{
      // console.log("!.合わせて１行で収まる")
      // !.合わせて１行で収まる
      let space_size = parseInt((printerWidth - str1_count - str2_count) / font_width)
      str = str1 + str_for(" ", space_size) + str2 + "\n"
    }  
  }

  input_json = setPText(input_json, str)
  return input_json
}

// [   ] + [100] + [   ] + [\n1,000,000]
const setP3Comuns = (input_json, str1, str2, str3, printerWidth = 48) => {
  input_json = setPTextSize(input_json, 1, 1)

  let str = ""
  str1 = String(str1)
  str2 = String(str2)
  str3 = String(str3)
  let str1_count = count(str1)
  let str1_amari = str1_count % printerWidth
  let str_other = str_for(" ", 3) + str_fit(str2, 3) + str_for(" ", 3) + str_fit(str3, 10)
  let str_other_size = count(str_other)

  if(str1_count > printerWidth){
    // console.log("3.タイトルが横より長い")
    // 3.タイトルが横より長い
    let space_size = parseInt(printerWidth - str1_amari - str_other_size)
    str = str1 + str_for(" ", space_size) + str_other + "\n"
  }else{
    if((str1_count + 19) > printerWidth){
      // console.log("2.タイトル・数が横より長い")
      // 2.タイトル・数が横より長い
      let space_size = parseInt(printerWidth - str_other_size)
      str = str1 + "\n" + str_for(" ", space_size) + str_other + "\n"
    }else{
      // console.log("!.合わせて１行で収まる")
      // !.合わせて１行で収まる
      let space_size = parseInt(printerWidth - str1_count- str_other_size)
      // console.log("space_size", str1_count, space_size, str_other_size)
      str = str1 + str_for(" ", space_size) + str_other + "\n"
    }  
  }
  input_json = setPText(input_json, str)
  return input_json
}

const count = (str) => {
  let len = 0;
  for (let i = 0; i < str.length; i++) {
    (str[i].match(/[ -~]/)) ? len += 1 : len += 2;
  }
  return len;
}

const str_for = (str, size) => {
  let str_out = "";
  for(let i = 0; i < size; i++){
    if(str){
      str_out = str_out + str
    }else{
      str_out = str_out
    }
  }
  return str_out;
}

const str_fit = (str, size) => {
  str = String(str)
  let str_count = count(str)
  if(str_count < size){
    str = str_for(" ", (size - str_count)) + str
  }
  return str
}

const numberFormat = (num) => {
  return (num || 0)
    .toString()
    .replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ","));
}

//////////////////////////////////

const getSample = (input_json) => {
  // レシート
  input_json = _.cloneDeep(recipet_sample)
  // オーダー
  let order_set = {
    is_first_order: true,
    table_name: "A1",
    created_at: "2022/11/08 12:13:14",
    staff_name: "アルバイト",
  }
  let order = {
    uid: "1234567890",
    order_type: "item",
    item_name: "瓶ビール（大）",
    count: 2,
    options: []
  }
  // console.log("before get sample",input_json)
  input_json = getOrder(input_json, order_set, order)
  // console.log("after get sample",input_json)
  return input_json
}

const getReciept = (input_json, {group, payment}) => {
  let shop =  window.storeCtl.state.shop.settings

  // 店舗情報
  input_json = setPTextAlign(input_json, "center")
  input_json = setPFeed(input_json, 10)
  input_json = setPTextSize(input_json, 1, 2)
  input_json = setPText(input_json, `${shop.name}\n`)
  input_json = setPFeed(input_json, 20)
  input_json = setPTextSize(input_json, 1, 1)
  if(shop.zipcode) {
    input_json = setPText(input_json, `〒${shop.zipcode}${shop.address_pref_text}${shop.address_shiku}${shop.address_cho}${shop.address_other}\n`)
    input_json = setPFeed(input_json, 10)
  }
  if(shop.tel){
    input_json = setPText(input_json, `Tel.${shop.tel}\n`)
    input_json = setPFeed(input_json, 10)
  }
  if(shop.invoice_id){
    input_json = setPText(input_json, `登録番号：${shop.invoice_id}\n`)
    input_json = setPFeed(input_json, 10)
  }
  input_json = setPText(input_json, "ご来店誠にありがとうございます。\n")
  input_json = setPFeed(input_json, 20)
  if(payment){
    input_json = setPTextAlign(input_json, "right")
    input_json = setPText(input_json, `テーブル:${payment.table_name}\n`)
    input_json = setPFeed(input_json, 5)
    input_json = setPText(input_json, `人数:${payment.member_size}名\n`)
    input_json = setPFeed(input_json, 5)
    input_json = setPText(input_json, `ID:${payment.uid}\n`)
    input_json = setPFeed(input_json, 5)
    input_json = setPText(input_json, `${dayjs(payment.group.exit_at).format("YYYY年MM月DD日(ddd) HH時mm分")}`)
    input_json = setPFeed(input_json, 1)  
  }else{
    input_json = setPTextAlign(input_json, "right")
    input_json = setPText(input_json, `テーブル:${group.table_name}\n`)
    input_json = setPFeed(input_json, 10)
  }

  // hr
  input_json = setPFeed(input_json, 10)  
  input_json = setPHr(input_json)
  input_json = setPFeed(input_json, 10)

  let obj = payment ? payment : group

  // orders
  input_json = setPTextAlign(input_json, "left")
  for(let i = 0; i < obj.orders.length; i++){
    let od = obj.orders[i]
    if(od.status === 'cancel') continue
    input_json = setP3Comuns(input_json, `${od.item_name}${od.order_type === 'viking_item' ? '(放)' : ''}${od.item_tax_rate === 8 ? '※' : ''}(\\${numberFormat(od.item_price - od.discount_price)})`, od.count, `\\${numberFormat((od.item_price - od.discount_price) * od.count)}`)
    // optioins
    for(let i = 0; i < od.options.length; i++){
      let option = od.options[i]
      for(let ii = 0; ii < option.settings.length; ii++){
        let setting = option.settings[ii]
        if(setting.count > 0){
          input_json = setP3Comuns(input_json, `　${setting.name}(\\${numberFormat(setting.price)})`, (setting.count * od.count), `\\${numberFormat((setting.price * setting.count * od.count))}`)
        }
      }
    }  
  }

  input_json = setPFeed(input_json, 10)
  input_json = setPText(input_json, `※は軽減税率対象商品\n`)

  // hr
  input_json = setPFeed(input_json, 10)  
  input_json = setPHr(input_json)
  input_json = setPFeed(input_json, 10)
  input_json = setPTextAlign(input_json, "left")

  // 割引・小計
  input_json = setP2Comuns(input_json, "割引・割増", `\\${numberFormat(obj.ajust_price)}`)
  let price = 0
  price += obj.ajusted_nomal_subtotal
  price += obj.ajusted_keigen_subtotal
  price += obj.ajusted_hikazei_subtotal
  if(shop.is_include_tax) price += obj.tax_sum
  input_json = setP2Comuns(input_json, "小計", `\\${numberFormat(price)}`)
  // hr
  input_json = setPFeed(input_json, 10)  
  input_json = setPHr(input_json)
  input_json = setPFeed(input_json, 10)
  // 内
  let includeTax = shop.is_include_tax ? "内：" : ""
  input_json = setP2Comuns(input_json, `${includeTax}標準税率対象メニュー`, `\\${numberFormat(obj.ajusted_nomal_subtotal)}`)
  input_json = setP2Comuns(input_json, `${includeTax}軽減税率対象メニュー`, `\\${numberFormat(obj.ajusted_keigen_subtotal)}`)
  input_json = setP2Comuns(input_json, `${includeTax}標準税`, `\\${numberFormat(obj.nomal_tax_price)}`)
  input_json = setP2Comuns(input_json, `${includeTax}軽減税`, `\\${numberFormat(obj.keigen_tax_price)}`)
  input_json = setP2Comuns(input_json, `${includeTax}消費税等合計`, `\\${numberFormat(obj.tax_sum)}`)
  // hr
  input_json = setPFeed(input_json, 10)  
  input_json = setPHr(input_json)
  input_json = setPFeed(input_json, 10)
  // sum
  input_json = setP2Comuns(input_json, "合計", `\\${numberFormat(obj.price_sum)}`, 2)
  input_json = setPTextSize(input_json, 1, 1)
  if(payment){
    formatted_payment_details(payment).forEach(pd => {
      input_json = setPFeed(input_json, 10)
      input_json = setP2Comuns(input_json, `${pd.payment_method_name}（お預かり）`, `\\${numberFormat(pd.azukari_price)}`)
    })
    input_json = setPFeed(input_json, 10)
    input_json = setP2Comuns(input_json, "お釣り", `\\${numberFormat(payment.otsuri_price)}`)    
  }

  // cut
  input_json = setPFeed(input_json, 20)
  input_json = setPCut(input_json)

  // drawer
  input_json = setDrawer(input_json)

  return input_json
}

const getRyoshusho = (input_json, payment, blank = false) => {
  let shop =  window.storeCtl.state.shop.settings

  input_json = setPTextAlign(input_json, "center")
  input_json = setPFeed(input_json, 50)  
  input_json = setPTextSize(input_json, 2, 2)
  input_json = setPText(input_json, `領収書\n`)
  input_json = setPFeed(input_json, 50)

  input_json = setPTextAlign(input_json, "right")
  input_json = setPTextSize(input_json, 1, 1)
  if(payment.uid) input_json = setPText(input_json, `No.${payment.uid}\n`)
  let pay_at = payment.group ? dayjs(payment.group.exit_at) : dayjs()
  input_json = setPText(input_json, `${pay_at.format("YYYY年MM月DD日(ddd) HH時mm分")}`)
  input_json = setPFeed(input_json, 50)

  input_json = setPTextAlign(input_json, "left")
  input_json = setPTextSize(input_json, 2, 2)
  input_json = setPTextReverse(input_json, "false", "true")
  input_json = setPText(input_json, `　　　　　　　　　　様\n`)
  input_json = setPFeed(input_json, 50)

  // price
  let price_str_all = null
  if(blank){
    price_str_all = str_for(" ", 22)
  }else{
    let price_str = `\\${numberFormat(payment.price_sum)}`
    let price_str_size = count(price_str)
    let space_size = 22 - price_str_size
    price_str_all = str_for(" ", space_size) + price_str      
  }
  input_json = setPText(input_json, price_str_all)
  input_json = setPTextSize(input_json, 1, 1)
  input_json = setPText(input_json, ` 印\n`)

  input_json = setPTextReverse(input_json, "false", "false")
  input_json = setPFeed(input_json, 25)
  input_json = setPTextSize(input_json, 1, 1)
  input_json = setPText(input_json, `但し、ご飲食代として\n上記領収いたしました。\n`)


  input_json = setPTextAlign(input_json, "right")
  input_json = setPText(input_json, `${shop.name}\n`)
  if(shop.zipcode) {
    input_json = setPText(input_json, `〒${shop.zipcode}\n`)
    input_json = setPText(input_json, `${shop.address_pref_text}${shop.address_shiku}\n${shop.address_cho}${shop.address_other}\n`)  
  }
  if(shop.tel) input_json = setPText(input_json, `TEL ${shop.tel}\n`)

  // cut
  input_json = setPFeed(input_json, 50)
  input_json = setPCut(input_json)

  return input_json
}

const getOrder = (input_json, order_set, order) => {
  input_json = setPFeed(input_json, 8)
  input_json = setPTextAlign(input_json, "left")

  // table time
  input_json = setPTextSize(input_json, 1, 1)
  let table_name = null
  if(order_set.is_first_order){
    table_name = order_set.table_name + " 【新規】"
  }else{
    table_name = order_set.table_name
  }
  input_json = setP2Comuns(input_json, table_name, `${dayjs(order_set.created_at).format("HH時mm分ss秒")}`, 1, 2)

  // item_name count
  input_json = setPFeed(input_json, 25)
  let item_name = null
  if(order.order_type === "item") item_name = order.item_name
  if(order.order_type === "viking") item_name = "[放]" + order.item_name
  if(order.order_type === "viking_item") item_name = "[放]" + order.item_name
  if(order.order_type === "custom_item") item_name = "[カ]" + order.item_name
  if(order.order_type === "gift") item_name = "[ギ]" + order.item_name
  input_json = setP1Comuns(input_json, item_name, 2, 2)
  input_json = setPTextSize(input_json, 1, 1)
  input_json = setPFeed(input_json, 15)

  // option
  let option_str = ""
  let is_first_option = true
  for(let i = 0; i < order.options.length; i++){
    let option = order.options[i]
    for(let ii = 0; ii < option.settings.length; ii++){
      let setting = option.settings[ii]
      if(setting.count > 0){
        let stg = is_first_option ? "" : ", "
        if(option.option_type === 'checkbox'){
          stg += `${setting.name}×${setting.count}`
        }else{
          stg += `${setting.name}`
        }
        option_str += stg
        is_first_option = false
      }
    }
  }
  if(is_first_option === false){
    input_json = setPFeed(input_json, 8)
    input_json = setPTextAlign(input_json, "left")
    input_json = setP1Comuns(input_json, option_str, 1, 2)
  }

  // hr
  input_json = setPTextSize(input_json, 1, 1)
  input_json = setPFeed(input_json, 8)  
  input_json = setPHr(input_json)
  input_json = setPFeed(input_json, 8)

  // 個数
  input_json = setP1Comuns(input_json, `個数 × ${order.count}`, 2, 2)

  // hr
  input_json = setPTextSize(input_json, 1, 1)
  input_json = setPFeed(input_json, 8)  
  input_json = setPHr(input_json)
  input_json = setPFeed(input_json, 8)

  // id / 担当
  input_json = setPTextSize(input_json, 1, 1)
  input_json = setP2Comuns(input_json, `ID:${order.uid}`, `主: ${order_set.staff_name}`)
  input_json = setPFeed(input_json, 8)

  // cut
  input_json = setPCut(input_json)

  return input_json
}

const getReport = (input_json, st) => {
  input_json = setPTextAlign(input_json, "center")
  input_json = setPFeed(input_json, 5)
  input_json = setPTextSize(input_json, 2, 2)
  input_json = setPText(input_json, `売上レポート\n`)
  input_json = setPFeed(input_json, 20)
  input_json = setPTextSize(input_json, 1, 1)
  input_json = setPText(input_json, `[${dayjs(st.date).format("YYYY年MM月DD日(ddd)")}]\n`)
  input_json = setPFeed(input_json, 50)

  // 売上
  input_json = setP2Comuns(input_json, `売上`, `\\${numberFormat(st.price_sum)}`, 2, 1) 
  input_json = setPFeed(input_json, 20)
  input_json = setP2Comuns(input_json, `　割引前売上`, `\\${numberFormat(st.price_sum + st.discount_sum)}`) 
  input_json = setP2Comuns(input_json, `　売上高（税込み）`, `\\${numberFormat(st.price_sum)}`) 
  input_json = setP2Comuns(input_json, `　売上高（税抜）`, `\\${numberFormat(st.price_sum - st.tax_sum)}`) 
  input_json = setP2Comuns(input_json, `　割引額`, `\\${numberFormat(st.discount_sum)}`) 
  input_json = setP2Comuns(input_json, `　消費税総額`, `\\${numberFormat(st.tax_sum)}`) 
  input_json = setP2Comuns(input_json, `　会計数`, `${numberFormat(st.account_size)}`) 

  // hr
  input_json = setPFeed(input_json, 8)  
  input_json = setPHr(input_json)
  input_json = setPFeed(input_json, 20)
  
  // 支払い別売上
  input_json = setP2Comuns(input_json, `支払い別売上`, ` `, 2, 1)
  input_json = setPFeed(input_json, 20)
  for(let i = 0; i < st.payments_pivot.length; i++){
    let pm = st.payments_pivot[i]
    input_json = setP2Comuns(input_json, `　${pm.name}`, `\\${numberFormat(pm.price_sum)}`) 
  }

  // hr
  input_json = setPFeed(input_json, 8)  
  input_json = setPHr(input_json)
  input_json = setPFeed(input_json, 20)

  // 現金状況
  input_json = setP2Comuns(input_json, `レジ金(現金)状況`, ` `, 2, 1)
  input_json = setPFeed(input_json, 20)
  input_json = setP2Comuns(input_json, `　繰越残高 A=(前日のL)`, `\\${numberFormat(st.cash_genkin_kurinobe)}`) 
  input_json = setP2Comuns(input_json, `　当日入金(つり銭用) B`, `\\${numberFormat(st.cash_tsurisen_junbi)}`) 
  input_json = setP2Comuns(input_json, `　現金売上高 C`, `\\${numberFormat(st.cash_genkin_uriage)}`) 
  input_json = setP2Comuns(input_json, `　出金(営業中) D`, `\\${numberFormat(st.cash_shukin_gaku * -1)}`) 
  input_json = setP2Comuns(input_json, `　入金(営業中) E`, `\\${numberFormat(st.cash_nyukin_gaku)}`) 
  let cashYosouzandaka = st.cash_genkin_kurinobe + st.cash_tsurisen_junbi + st.cash_genkin_uriage - st.cash_shukin_gaku + st.cash_nyukin_gaku 
  input_json = setP2Comuns(input_json, `　暫定残高 F=(A+B+CｰD+E)`, `\\${numberFormat(cashYosouzandaka)}`) 
  input_json = setP2Comuns(input_json, `　実際の残高 G`, `\\${numberFormat(st.cash_genkin_zandaka)}`) 
  let cashKabusokukin = st.cash_genkin_zandaka - cashYosouzandaka
  input_json = setP2Comuns(input_json, `　過不足金 H=(G-F)`, `\\${numberFormat(cashKabusokukin)}`) 

  // hr
  input_json = setPFeed(input_json, 8)  
  input_json = setPHr(input_json)
  input_json = setPFeed(input_json, 20)

  // 補正
  input_json = setP2Comuns(input_json, `補填`, ` `, 2, 1)
  input_json = setPFeed(input_json, 20)
  input_json = setP2Comuns(input_json, `　過不足金補填 I`, `\\${numberFormat(st.fix_sagakuhosei)}`) 
  input_json = setP2Comuns(input_json, `　補正後残高 J=(G+I)`, `\\${numberFormat(st.fix_hoseigozandaka)}`) 
  input_json = setP2Comuns(input_json, `　最終入出金 K`, `\\${numberFormat(st.fix_saishu_shutunyukin)}`) 
  input_json = setP2Comuns(input_json, `　繰越残高 L=(J±K)`, `\\${numberFormat(st.fix_kurikoshi_danzaka)}`) 
  input_json = setP2Comuns(input_json, `　スタッフ`, st.fix_staff_name ? st.fix_staff_name : "-") 

  // cut
  input_json = setPFeed(input_json, 20)
  input_json = setPCut(input_json)

  return input_json
}

//////////////////////////////////

const getDefaultState = () => {
  return {
    // printer
    printer_name: null,
    printer_sheet_white: false,
    priiner_direction: false,
    print_zero_option: false,
    print_hodai_item: false,
    print_auto_on_kitchen: false,
    print_auto_on_accounting: false,
    print_auto_on_settlement: false,
  }
}

export const state = () => (getDefaultState())
export const mutations = {
  resetAll(state){
    Object.assign(state, getDefaultState())
  },

  setPrinterName(state, name){
    state.printer_name = name
  },
  setPrinterSettings(state, printer){
    state.printer_sheet_white = ["true", true].includes(printer.printer_sheet_white) ? true : false
    state.priiner_direction = ["true", true].includes(printer.priiner_direction) ? true : false
    state.print_zero_option = ["true", true].includes(printer.print_zero_option) ? true : false
    state.print_hodai_item = ["true", true].includes(printer.print_hodai_item) ? true : false
    state.print_auto_on_kitchen = ["true", true].includes(printer.print_auto_on_kitchen) ? true : false
    state.print_auto_on_accounting = ["true", true].includes(printer.print_auto_on_accounting) ? true : false
    state.print_auto_on_settlement = ["true", true].includes(printer.print_auto_on_settlement) ? true : false
  },

  callBackStatus(state, json){
    // window.alert(json.status)
    if(json.status === "success"){
      state.printer_name = json.printer_name
    }else{
      state.printer_name = "未接続"
      window.storeCtl.commit("alert/setError", json.message)
    }
  },

  callFinishPrint(state, json){
    // window.alert(json.status)
    console.log("callFinishPrint", json)  
    if(json.status !== "success"){
      window.storeCtl.commit("alert/setError", json.message)
    }
  },

}
export const actions = {
  async getPrinterStatus(context){
    try{
      let input_json = _.cloneDeep(base_json)
      let time = dayjs()
      input_json.request_type = "get_status"
      input_json.timestamp = time.format('YYYY/MM/DD HH:mm:ss')
      input_json.request_uid = `get_status_${time.format('YYYY-MM-DD-HH-mm-ss')}`
      let json_str = JSON.stringify(input_json)
      window.webkit.messageHandlers.getPrinterStatus.postMessage(json_str)
    }catch(e){
      window.storeCtl.commit("alert/setError", "プリンターが見つかりません")
    }
  },

  async requestPrint(context, {type, order_set, group, payment, shop_trend}){

    console.log("requestPrint", {type, order_set, group, payment, shop_trend})
    
    // テンプレ作成
    let input_json = _.cloneDeep(base_json)
    let time = dayjs()
    let object_id = null
    if(type === "sample"){
      // console.log("use sample")
      object_id = `sample`
      //// JSON作成
      input_json = getSample(input_json)
    }else if(type === "reciept"){
      // json作成
      // console.log("reciept", group, payment)
      input_json = getReciept(input_json, {group: group, payment: payment})
    }else if(type === "ryoshusho"){
      // json作成
      // console.log("reciept", group, payment)
      input_json = getRyoshusho(input_json, payment)
    }else if(type === "ryoshusho_blank"){
      // json作成
      // console.log("reciept", group, payment)
      input_json = getRyoshusho(input_json, payment, true)
    }else if(type === "kitchen"){
      object_id = `p${order_set.id}`
      for(let i = 0; i < order_set.orders.length; i++){
        let od = order_set.orders[i]
        // json作成
        input_json = getOrder(input_json, order_set, od)

      }
    }else if(type === "report"){
      object_id = `st${shop_trend.id}`
      // json作成
      input_json = getReport(input_json, shop_trend)
    }else{
      return false
    }

    input_json.request_type = type
    input_json.timestamp = time.format('YYYY/MM/DD HH:mm:ss')
    input_json.request_uid = `${type}_${object_id}_${time.format('YYYY-MM-DD-HH-mm-ss')}`

    try{
      console.log(input_json)
      // window.storeCtl.commit("alert/setError", input_json)
      let json_str = JSON.stringify(input_json)
      window.webkit.messageHandlers.requestPrint.postMessage(json_str);

      // ここでプリントしましたデータ送信
      if(type === "kitchen"){
        setTimeout(() => {
          OSPrintDone(order_set)
        }, 1000);
      }
    }catch(e){
      window.storeCtl.commit("alert/setError", "プリンターが見つかりません")
    }
  },

}
